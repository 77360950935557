import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "order" ]
  static values = { url: String }

  sort(event) {
    event.preventDefault()
    window.location = this.urlValue + `?work_order[order]=${event.target.value}`
  }
}
